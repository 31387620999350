import { createContext, useState } from 'react'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const [posValue, setPosValue] = useState(0)
  const changeValue = e => setPosValue(e.target.value)

  return (
    <GlobalContext.Provider
      value={{
        test: 'Hola Mundo!',
        posValue,
        changeValue
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
