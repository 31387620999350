import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/benelli.glb')
  useFrame(() => {
    group.current.rotation.y += 0.01
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 3]} scale={0.0015}>
        <mesh
          geometry={nodes.cable_de_bateria001_1.geometry}
          material={materials['default.001']}
        >
          <meshPhongMaterial color={'silver'} />
        </mesh>

        <mesh
          geometry={nodes.cable_de_bateria001_2.geometry}
          material={materials['Node.002']}
        >
          <meshPhongMaterial color={'orange'} shininess={70} specular={50} />
        </mesh>
        <mesh
          geometry={nodes.cable_de_bateria001_3.geometry}
          material={materials['Node.003']}
        >
          <meshPhongMaterial color={'#303030'}  shininess={70} specular={50} />
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/benelli.glb')


