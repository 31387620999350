import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

const BoxModel = ({ ...props }) => {
  const group = useRef()
  const box = useRef()
  const cono = useRef()

  // const { nodes, materials } = useGLTF('/ScrollTest.glb')
  const { nodes } = useGLTF('/ScrollTest.glb')

  document.addEventListener('scroll', () => {
    if (![null, undefined].includes(box.current)) {
      box.current.rotation.y += 0.01
    }

    if (![null, undefined].includes(cono.current)) {
      cono.current.rotation.y -= 0.01
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Cube.geometry} ref={box}>
        <meshPhongMaterial color={'dodgerblue'} />
      </mesh>

      <mesh
        ref={cono}
        geometry={nodes.Cono.geometry}
        material={nodes.Cono.material}
        position={[2.69, 0.92, -0.08]}
        scale={[1, 1, 0.55]}
      >
        <meshPhongMaterial color={'deeppink'} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/ScrollTest.glb')

export default BoxModel
