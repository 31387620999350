import React, { useState } from "react";
import ReactDOM from "react-dom";
import { GlobalProvider } from "./context/global";
import Options from "./components/ComponentsList/ComponentsList";
import { ReactComponent as ChevronRight } from "assets/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "assets/chevron-left.svg";
import "./index.css";

const App = () => {
  const filteredOpt = Options.filter((option) => option.show);
  const limit = filteredOpt.length - 1;
  const [currentOption, setOption] = useState(0);
  const [right, setRight] = useState("white");
  const [left, setLeft] = useState("white");

  const changeNext = () => {
    setOption(currentOption + 1);
    if (currentOption >= limit) {
      setOption(0);
    }
  };

  const changePrevious = () => {
    setOption(currentOption - 1);
    if (currentOption === 0) {
      setOption(limit);
    }
  };

  return (
    <div className="App">
      <div className="btnCont left">
        <button
          onClick={changePrevious}
          className="btn"
          onMouseOver={() => setLeft("black")}
          onMouseLeave={() => setLeft("white")}
        >
          <ChevronLeft width={20} fill={left} />
        </button>
      </div>

      <div className="btnCont right">
        <button
          onClick={changeNext}
          className="btn"
          onMouseOver={() => setRight("black")}
          onMouseLeave={() => setRight("white")}
        >
          <ChevronRight width={20} fill={right} />
        </button>
      </div>

      <div className="floatTitle">
        {filteredOpt[currentOption].name && (
          <div>{filteredOpt[currentOption].name}</div>
        )}
      </div>

      <div className="canvasCont">{filteredOpt[currentOption].component}</div>

      <div className="floatInteractive">
        {filteredOpt[currentOption].isInteractive && (
          <div>
            Interactivo: Con click, arrastrar o presionar algun botón ejecutará
            alguna acción
          </div>
        )}
      </div>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
