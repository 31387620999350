import React from 'react'
import { OrbitControls } from '@react-three/drei'
import { DoubleSide } from 'three'

const Box = ({ position, color }) => {
  return (
    <mesh position={position}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={color} />
    </mesh>
  )
}

const Circle = ({ position, color }) => {
  return (
    <mesh position={position}>
      <sphereGeometry args={[0.7, 32, 32]} />
      <meshPhongMaterial color={color} />
    </mesh>
  )
}

const Floor = ({ color }) => {
  return (
    <mesh
      receiveShadow
      rotation={[-Math.PI / 2, 0, 0]}
      position={[0, -0.51, 0]}
    >
      <planeBufferGeometry args={[5, 5]} />
      <meshPhongMaterial color={color} side={DoubleSide} />
    </mesh>
  )
}

const BasicTest = () => {
  return (
    <>
      <Circle position={[0, 0.2, 0]} color="gold" />
      <Box position={[2, 0, 0]} color="dodgerblue" />
      <Box position={[-2, 0, 0]} color="hotpink" />
      <Floor color="#505050" />
      <OrbitControls />
    </>
  )
}

export default BasicTest
