import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import BasicTest from "../BasicTest/BasicTest";
import JoshDev from "../JoshDev/JoshDev";
import JoshDev2022 from "../JoshDev2022/JoshDev-2022";
import ScrollTest from "../ScrollTest/ScrollTest";
import Benelli from "../Benelli/Benelli";
import Sphere from "../Sphere/Sphere";
import Laser from "../Laser/Laserschwert.js";
import Bot from "../Bot/Bot.jsx";
import GlobalLights from "components/GlobalLights/GloblalLights";
import SpecialLights from "components/SpecialLight/SpecialLights";
import Loader from "components/Lottie/Lottie";

/**
 * Posiciones [0, 0, 0]
 * [Horizontal, Vertical, Eje Z]
 */

const Options = [
  {
    name: "Bienvenido",
    show: true,
    isInteractive: false,
    component: (
      <div className="initial">
        <h1 className="title">JoshDev | Three</h1>
        <h3 className="subtitle">Ejemplos de Three.js</h3>
        <h6 className="mini">
          Esta página es experimental y podría fallar si no posee una buena
          conexión a internet.
        </h6>
        <h6 className="mini">
          Preferible ver en resolucion de escritorio.
        </h6>
      </div>
    ),
  },
  {
    name: "JoshDev (Logo 2022)",
    show: true,
    isInteractive: false,
    component: (
      <Suspense fallback={<Loader />}>
        <Canvas camera={{ fov: 45 }}>
          <SpecialLights />
          <JoshDev2022 />
        </Canvas>
      </Suspense>
    ),
  },
  {
    name: "JoshDev (Logo 2020)",
    show: true,
    isInteractive: false,
    component: (
      <Suspense fallback={<Loader />}>
        <Canvas>
          <SpecialLights />
          <JoshDev />
        </Canvas>
      </Suspense>
    ),
  },
  {
    name: "Bicicleta",
    show: true,
    isInteractive: false,
    component: (
      <Suspense fallback={<Loader />}>
        <Canvas>
          <pointLight color={"white"} intensity={0.7} position={[0, 0, 10]} />
          <ambientLight color={"white"} intensity={0.1} />
          <Benelli />
        </Canvas>
      </Suspense>
    ),
  },
  {
    name: "Objetos Basicos",
    show: true,
    isInteractive: true,
    component: (
      <Canvas>
        <pointLight color={"white"} intensity={0.5} position={[0, 10, 10]} />
        <ambientLight color={"white"} intensity={0.1} />
        <BasicTest />
      </Canvas>
    ),
  },
  {
    name: 'Bot ("x" encender/apagar luces)',
    show: true,
    isInteractive: true,
    component: (
      <Canvas>
        <Bot />
      </Canvas>
    ),
  },
  {
    name: "Lightsaber",
    show: true,
    isInteractive: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Canvas>
          <SpecialLights />
          <Laser />
        </Canvas>
      </Suspense>
    ),
  },
  {
    name: "Scroll",
    show: true,
    isInteractive: false,
    component: (
      <>
        <div className="special1">Scroll Down</div>
        <Suspense fallback={<Loader />}>
          <Canvas className="canvasCont">
            <pointLight color={"white"} intensity={0.5} position={[0, 0, 10]} />
            <ScrollTest />
          </Canvas>
        </Suspense>
        <div className="special1">End</div>
      </>
    ),
  },
  {
    name: "Esfera render animado",
    show: true,
    isInteractive: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Canvas>
          <GlobalLights isActive={true} />
          <Sphere />
        </Canvas>
      </Suspense>
    ),
  },
  {
    name: "Final",
    show: true,
    isInteractive: false,
    component: (
      <div className="initial">
        <h2>JoshDev | Three.js</h2>
        <p>
          <span>Los modelos 3D provienen de diversas fuentes.</span>
          <br />
          <ul>
            <li>
              <span>{"Billy Briceño | "}</span>
              <a href="https://www.instagram.com/billdesign_/" className="link">
                billdesign_
              </a>
            </li>
            <li>
              <span>{"Free3D | "}</span>
              <a href="https://free3d.com" className="link">
                Free3D
              </a>
            </li>
            <li>
              <span>{"Joshua Mora | "}</span>
              <a href="https://joshdev.space" className="link">
                JoshDev
              </a>
            </li>
          </ul>
          <br />
          <span>Todos libres de usar en internet.</span>
        </p>
      </div>
    ),
  },
];

export default Options;
