import React from 'react'

const SpecialLights = () => {
  return (
    <>
      <pointLight color={'skyblue'} intensity={0.1} position={[-5, 10, 0]} />
      <pointLight color={'skyblue'} intensity={0.1} position={[5, 10, 0]} />

      <pointLight color={'#ffacff'} intensity={0.1} position={[0, 10, 0]} />
      <pointLight color={'#ffacff'} intensity={0.1} position={[-10, 10, 0]} />
      <pointLight color={'#ffacff'} intensity={0.1} position={[10, 10, 0]} />

      <pointLight color={'white'} intensity={0.1} position={[0, 0, 10]} />
      <pointLight color={'white'} intensity={0.1} position={[10, 0, 10]} />
      <pointLight color={'white'} intensity={0.1} position={[-10, 0, 10]} />
      <pointLight color={'white'} intensity={0.1} position={[20, 0, 0]} />
      <pointLight color={'white'} intensity={0.1} position={[-20, 0, 0]} />

      <pointLight color={'#ffacff'} intensity={0.1} position={[-5, -10, 0]} />
      <pointLight color={'#ffacff'} intensity={0.1} position={[5, -10, 0]} />

      <pointLight color={'#97fff5'} intensity={0.1} position={[0, -10, 0]} />
      <pointLight color={'#97fff5'} intensity={0.1} position={[-10, -10, 0]} />
      <pointLight color={'#97fff5'} intensity={0.1} position={[10, -10, 0]} />
    </>
  )
}

export default SpecialLights
