import React, { useRef, useEffect, useState } from 'react'
import { useGLTF, OrbitControls } from '@react-three/drei'

const Bot = ({ ...props }) => {
  const group = useRef()
  const { nodes, materials } = useGLTF('/bot.glb')
  const [light, setLight] = useState(0.5)

  useEffect(() => {
    const botEvent = e => {
      if (e.keyCode === 120) {
        setLight(light === 0 ? 0.5 : 0)
      }
    }
    window.addEventListener('keypress', botEvent)
  })

  return (
    <>
      <OrbitControls />
      <pointLight color={'white'} intensity={light} position={[0, 7, 5]} />
      <pointLight color={'white'} intensity={light} position={[0, -7, 5]} />
      <group ref={group} {...props} dispose={null}>
        <mesh
          geometry={nodes.Cube.geometry}
          material={materials.Material}
          position={[0.02, 0, -0.02]}
          rotation={[0, -1.57, 0]}
          scale={[0.38, 0.88, 0.65]}
        />
        <mesh
          geometry={nodes.Cube001.geometry}
          material={materials.Material}
          position={[0.92, 0, -0.02]}
          rotation={[0, -1.57, 0]}
          scale={[0.17, 0.61, 0.17]}
        />
        <mesh
          geometry={nodes.Cube002.geometry}
          material={materials.Material}
          position={[-0.88, 0, -0.02]}
          rotation={[0, -1.57, 0]}
          scale={[0.17, 0.61, 0.17]}
        />
        <mesh
          geometry={nodes.Cube003.geometry}
          material={materials.Material}
          position={[0.02, 1.25, -0.02]}
          rotation={[0, -1.57, 0]}
          scale={[0.38, 0.29, 0.65]}
        />
        <mesh
          geometry={nodes.Cube004.geometry}
          material={materials.Material}
          position={[-0.38, -1.25, -0.02]}
          rotation={[0, -1.57, 0]}
          scale={[0.38, 0.3, 0.18]}
        />
        <mesh
          geometry={nodes.Cube006.geometry}
          material={materials.Material}
          position={[0.42, -1.25, -0.02]}
          rotation={[0, -1.57, 0]}
          scale={[0.38, 0.3, 0.18]}
        />
        <mesh
          geometry={nodes.Esfera.geometry}
          material={materials['Material.001']}
          position={[0.02, 0, 0.12]}
          rotation={[0, -1.57, 0]}
          scale={0.05}
        />
      </group>
    </>
  )
}

useGLTF.preload('/bot.glb')

export default Bot
