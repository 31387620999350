import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'

const JoshDev = () => {
  const group = useRef()
  const { nodes } = useGLTF('/JoshDev.glb')
  useFrame(() => {
    group.current.rotation.y += 0.01
  })

  return (
    <group ref={group} dispose={null}>
      <mesh
        geometry={nodes.Cubo005.geometry}
        material={nodes.Cubo005.material}
        position={[0, 0, 0]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.102, 0.08, 0.207]}
      >
        <meshPhongMaterial
          color={'#0061c2'}
          shininess={80}
          specular={'#0061c2'}
        />
      </mesh>
    </group>
  )
}

useGLTF.preload('/JoshDev.glb')

export default JoshDev
