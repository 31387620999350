import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/JoshDev-2022.glb')

  useFrame(() => {
    group.current.rotation.y += 0.01
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.path34242.geometry}
        material={materials['SVGMat.001']}
        position={[-0.14, 0.03, 0]}
        rotation={[1.57, 0, 0]}
        scale={[17.3, 1, 17.3]}
      />
      <mesh
        geometry={nodes.path34244.geometry}
        material={materials['SVGMat.002']}
        position={[0.14, -0.03, 0]}
        rotation={[1.57, 0, 0]}
        scale={[17.3, 1, 17.3]}
      />
    </group>
  )
}

useGLTF.preload('/JoshDev-2022.glb')
