import React, { useRef } from 'react'
import { useGLTF, OrbitControls } from '@react-three/drei'

export default function Model() {
  const group = useRef()
  const { nodes, materials } = useGLTF('/laserschwert.glb')
  return (
    <>
      <OrbitControls />
      <group ref={group} dispose={null}>
        <group rotation={[Math.PI / 2, 0, 0]}  scale={[0.64, 4.16, 0.64]}>
          <mesh
            geometry={nodes.Cylinder_1.geometry}
            material={materials['Material.001']}
          >
            <meshPhongMaterial color={"#303030"} specular={70}/>
          </mesh>
          <mesh
            geometry={nodes.Cylinder_2.geometry}
            material={materials['Material.003']}
          >
            <meshPhongMaterial color={"grey"} specular={70} shininess={100}/>
          </mesh>
          <mesh
            geometry={nodes.Cylinder_3.geometry}
            material={materials.Material}
          >
            <meshPhongMaterial color={"grey"} specular={70} shininess={100}/>
          </mesh>
          <mesh
            geometry={nodes.Cylinder_4.geometry}
            material={materials['Material.005']}
          />
        </group>
      </group>
    </>
  )
}

useGLTF.preload('/laserschwert.glb')
